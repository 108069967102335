<script setup>
import { watch } from 'vue';
import { usePage } from '@inertiajs/vue3';
import { useToast } from 'vue-toastification';

const toast = useToast();

watch(
    () => usePage().props.jetstream.flash?.banner,
    (flash) => {
        let toastId = null;

        if (usePage().props.jetstream.flash?.banner) {
            toastId = toast(usePage().props.jetstream.flash?.banner, {
                type: usePage().props.jetstream.flash?.bannerStyle,
            });
        }

        if (toastId !== null) {
            setTimeout(() => toast.dismiss(toastId), 5000);
        }
    },
    {
        deep: true,
    }
);
</script>

<template></template>
